import { socialActivitiesObjs } from "src/utils";

const initialState = {
  category: '',
  image: '',
  title: '',
  unit_cost: '',
  description: '',
  endsIn:'',
  sponsors:[]
}

export function pageAreaReducer(state = initialState, action){
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "PAGE_SET_AREA":
      return action.data;
  }
  return state;
}

export function countryAllowedReducer(state={type:'',data:{},message:''},action){
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'ALLOWED_COUNTRY_REQUEST':
      return {type:"request",data: {}}
    case "ALLOWED_COUNTRY_SUCCESS":
      return {type: action.data.type, data:action.data}
  }
  return state;
}

export function pageThoughtsReducer(state = {heading: '', txt: ''}, action){
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "PAGE_RESET_THOUGHTS":
      return "";
    case "PAGE_SET_THOUGHTS":
      return action.data;
  }
  return state;
}

export function rewardListReducer(state={type:'',data:[],message:''},action){
  let obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'REWARD_LIST_REQUEST':
      return {type:"request",data:[]}
    case 'REWARD_LIST_SUCCESS':
      return {type:"success",data:action.data}
    case 'REWARD_LIST_ERROR':
      return {...state,type:"error",message:action.message}
    case 'REWARD_ADD_SUCCESS':
      if(obj.type=='success'){
        obj.data.push(action.data)
      }
      return obj;
    case 'REWARD_COMPLETED':
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id == action.data.id){
          obj.data[index].status = 2;
          obj.data[index].drop_end_time = new Date();

        }
      }
      return obj;
    case 'REWARD_UPDATE_SUCCESS':
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id == action.data.id){
          obj.data[index] = action.data;
        }
      }
      return obj;
    case 'REWARD_DELETE_SUCCESS':
      obj.data = obj.data.filter((item) => item.id !== action.data.id);
      return obj;
    case "REWARD_DUPLICATE_SUCCESS":
      if(obj.type=='success'){
        obj.data.push(action.data)
      }
      return obj;
  }
  return state;
}

export function rewardCRDReducer(state={type:'',data:{}, api_error: false,message:''},action){
  let obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'REWARD_CRD_RESET':
      return {type:'',data:{},message:'', api_error: state.api_error}
    case 'REWARD_CRD_REQUEST':
      return {...state,type:'request',message:'', api_error: state.api_error};
    case 'REWARD_DELETE_SUCCESS':
      return {type:'success',data:{},message:'Reward deleted successfully!'}
    case 'REWARD_CRD_SET':
      if(obj.type =='request'){
        return obj;
      }
      return {type:'success',data:action.data,message:'',api_error: state.api_error}
    case 'REWARD_CRD_DB_SET':
      let dbData = {
        reward_drop_id:'',
        community_type: '',
        category:'',
        image:'',
        title:'',
        description:'',
        unit_cost: "",
        quantity:'',
        schedule_now: true,
        is_coming_soon: true,
        allow_reward_end_date: false,
        allow_gift: false,
        startAt: '',
        endsIn:'',
        allow_published:'',
        sponsors:[],
        claim_condition: [],
        claim_condition_message: socialActivitiesObjs(),
        is_limited_quantity: false,
        is_one_time_use: true,
        drop_total_claim: 0,
        redeem_points: null,
        webhook_details: null,
        published_date: '',
        eventPass: {
          event_date: "",
          url: "",
          details: "",
          unique_key: ""
        },
        couponCodes:{
          code:"",
          website:"",
          details:""
        },
        urls:[{
          Url:"",
          ContentName:""
        }],
        allowList:{
          project_name: "",
          project_website: "",
          price: "",
          network: "",
          discord_url: "",
          description : ""
        },
        merchandise: {
          details: "",
          support_contact: "",
          allow_shipping_info: true,
          allow_size: false
        },
        raffle: {
          max_winners: "",
          registrant_message: "",
          winner_message: "",
          non_winner_message: "",
          contact_email: ""
        },
        poll: {
          question: "",
          choices: ["",""],
          allow_comment: true,
        },
        custom: {
          details: ""
        },
        member_selection: {
          choices: ["",""],
          allow_comment: true,
          allow_shipping_info: true,
        }
      }
      if(action.data){
        let reward_drop_data = action.data;
        // return
        dbData = {
            community_type: reward_drop_data?.community_type ? reward_drop_data.community_type : '',
            category: reward_drop_data?.drop_type ? reward_drop_data.drop_type : '',
            image: reward_drop_data?.image ? reward_drop_data.image : '',
            title: reward_drop_data?.title ? reward_drop_data.title : '',
            description: reward_drop_data?.details ? reward_drop_data.details : '',
            unit_cost: reward_drop_data?.unit_cost ? reward_drop_data.unit_cost : '',
            quantity: reward_drop_data?.quantity ? reward_drop_data.quantity : '',
            schedule_now: (typeof reward_drop_data.is_schedule_now !== 'undefined') ? reward_drop_data.is_schedule_now : true,
            is_coming_soon: reward_drop_data?.is_coming_soon ? reward_drop_data.is_coming_soon : false,
            startAt: reward_drop_data?.published_date ? reward_drop_data.published_date : '',
            endsIn: reward_drop_data?.end_date ? reward_drop_data.end_date : '',
            allow_reward_end_date: reward_drop_data?.end_date ? true : false,
            allow_reward_page: reward_drop_data.allow_reward_page !== null ? reward_drop_data.allow_reward_page : false,
            allow_embed_page: reward_drop_data.allow_embed_page !== null ? reward_drop_data.allow_embed_page : false,
            allow_published: reward_drop_data?.status == 1,
            is_limited_quantity: reward_drop_data?.is_limited_quantity ? reward_drop_data.is_limited_quantity : false,
            is_one_time_use:  reward_drop_data.is_one_time_use ,
            allow_gift:  reward_drop_data.allow_gift ,
            couponCodes: reward_drop_data?.drop_type_data ? {
              code: reward_drop_data.drop_type_data[0]?.coupon_code ? reward_drop_data.drop_type_data[0]?.coupon_code:"",
              website: reward_drop_data.drop_type_data[0]?.website,
              details: reward_drop_data.drop_type_data[0]?.details
            } : {
              code:"",
              website: "",
              details: ""
            },
            eventPass: reward_drop_data?.drop_type_data ? {
              event_date: reward_drop_data.drop_type_data[0]?.event_date ? reward_drop_data.drop_type_data[0]?.event_date:"",
              website: reward_drop_data.drop_type_data[0]?.website,
              details: reward_drop_data.drop_type_data[0]?.details,
              unique_key: reward_drop_data.drop_type_data[0]?.unique_key
            } : {
              event_date:"",
              website: "",
              details: ""
            },
            urls: reward_drop_data?.drop_type_data ? reward_drop_data.drop_type_data.map((d) => {
              return {
                'Url': d.content_url,
                'ContentName': d.button_text
              }
            }) : [],
            allowList: reward_drop_data?.drop_type_data ? {
              project_name: reward_drop_data.drop_type_data[0]?.project_name ? reward_drop_data.drop_type_data[0].project_name : "",
              project_website: reward_drop_data.drop_type_data[0]?.website ? reward_drop_data.drop_type_data[0].website : "",
              price: reward_drop_data.drop_type_data[0]?.price ? reward_drop_data.drop_type_data[0].price : "",
              network: reward_drop_data.drop_type_data[0]?.network ? reward_drop_data.drop_type_data[0].network : "",
              discord_url: reward_drop_data.drop_type_data[0]?.discord_url ? reward_drop_data.drop_type_data[0].discord_url : "",
              mint_date : reward_drop_data.drop_type_data[0]?.mint_date ? reward_drop_data.drop_type_data[0].mint_date : "",
              description : reward_drop_data.drop_type_data[0]?.description ? reward_drop_data.drop_type_data[0].description : ""
            } : {
              project_name: "",
              project_website: "",
              price: "",
              network: "",
              discord_url: "",
              mint_date: "",
              description : ""
            },
            merchandise: reward_drop_data?.drop_type_data ? {
              details: reward_drop_data.drop_type_data[0]?.details ? reward_drop_data.drop_type_data[0]?.details:"",
              support_contact: reward_drop_data.drop_type_data[0]?.support_contact,
              allow_shipping_info: reward_drop_data.drop_type_data[0]?.allow_shipping_info,
              allow_size: reward_drop_data.drop_type_data[0]?.allow_size
            } : {
              details: "",
              support_contact:"",
              allow_shipping_info: "",
              allow_size: ""
            },
            raffle: reward_drop_data?.drop_type_data ? {
              max_winners: reward_drop_data.drop_type_data[0]?.max_winners ? reward_drop_data.drop_type_data[0]?.max_winners:"",
              registrant_message: reward_drop_data.drop_type_data[0]?.registrant_message,
              winner_message: reward_drop_data.drop_type_data[0]?.winner_message,
              non_winner_message: reward_drop_data.drop_type_data[0]?.non_winner_message,
              contact_email: reward_drop_data.drop_type_data[0]?.contact_email
            } : {
              max_winners: "",
              registrant_message:"",
              winner_message: "",
              non_winner_message: "",
              contact_email: ""
            },
            poll: reward_drop_data?.drop_type_data ? {
              question: reward_drop_data.drop_type_data[0]?.question == null ? "" : reward_drop_data.drop_type_data[0]?.question,
              choices: reward_drop_data.drop_type_data[0]?.choices == null ? ["", ""] : reward_drop_data.drop_type_data[0]?.choices,
              allow_comment: reward_drop_data.drop_type_data[0]?.allow_comment
            } : {
              question:"",
              choices: ["",""],
              allow_comment: ""
            },
            member_selection: reward_drop_data?.drop_type_data ? {
              choices: reward_drop_data.drop_type_data[0]?.choices == null ? ["", ""] : reward_drop_data.drop_type_data[0]?.choices,
              allow_comment: reward_drop_data.drop_type_data[0]?.allow_comment,
              allow_shipping_info: reward_drop_data.drop_type_data[0]?.allow_shipping_info,
            } : {
              choices: ["",""],
              allow_comment: "",
              allow_shipping_info: "",
            },
            custom: reward_drop_data?.drop_type_data ? {
              details: reward_drop_data.drop_type_data[0]?.details ? reward_drop_data.drop_type_data[0]?.details:"",
            } : {
              details: ""
            },
            sponsors: reward_drop_data?.sponsor_collections ? reward_drop_data.sponsor_collections.map(item => {
              return {
                id: item.id,
                logo_image: item.logo_image,
                title: item.title,
                total_supply_val: item.total_supply,
                traits: item.traits
              }
            }) : [],
            claim_condition: reward_drop_data?.claim_condition ? reward_drop_data?.claim_condition : [],
            claim_condition_message: reward_drop_data?.claim_condition_message,
            reward_drop_id: reward_drop_data?.id ? reward_drop_data.id : '',
            drop_total_claim: reward_drop_data?.drop_total_claim,
            published_date: reward_drop_data?.published_date,
            redeem_points: reward_drop_data?.redeem_points,
            webhook_details: reward_drop_data?.webhook_details,
        }
      }
      return {type:'success',data:dbData,message:'', api_error: state.api_error}
    case 'REWARD_CRD_ERROR':
      return {...state,type:'error', api_error: true,message:action.message}
    case "RESET_REWARD_CRD_ERROR":
      return {...state, api_error: false, message:""}
  }
  return state;
}

export function rewardDropReducer(state = {type:'',data:[],reward_update: '',delete_status:''}, action){
  let obj;
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "REWARD_DROP_ALL_RESET":
     return  {type:'',data:[]}
    case "REWARD_DROP_ALL_REQUEST":
      return  {type:'request',data:[]}
    case "REWARD_DROP_ALL":
      obj = {type:'success', data: action.drops, currentPage:action.currentPage, perPage:action.perPage, totalRec: action.totalRec, reward_update:''};
      return obj;
    case "REWARD_DROP_CRUD_REQUEST":
      return  {reward_update:'request',data:[]}
    case "REWARD_DROP_CRUD_CREATE":
      obj = [...state.data];
      obj.push(action.data);
      return {type:'success', data: obj, reward_update: action.data.id};
    case "REWARD_DROP_CRUD_UPDATE":
      obj = {...state};
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id == action.data.id){
          obj.data[index] = action.data;
        }
      }
      return {obj, type: 'success', reward_update: 'success'};
    case "REWARD_DROP_CRUD_DELETE":
      obj = {...state};
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id == action.data.id){
          obj.data.splice(index,1);
        }
      }
      return obj;
    case "REWARD_DROP_CRUD_ERROR":
      return {type:'error',data:action.data}
    case "DELETE_REWARD_REQUEST":
      return{...state, delete_status: 'request'};
    case "DELETE_REWARD_SUCCESS":
      let data = state.data
      return{...state, delete_status: 'success', data:data.filter(item => item.id !== action.data)};
    case "DELETE_REWARD_ERROR":
      return{...state, delete_status: 'error', message: action.message};
  }
  return state;
}

export function toastReducer(state={show:false,type:"",message:"",customStyle:{}, isCustomStyle: false},action){
  let obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'SHOW_TOAST':
      return obj={show:true,type:action.data.type,message:action.data.message, customStyle:action.data.customStyle,isCustomStyle: action.data.isCustomStyle}
    case 'HIDE_TOAST':
      return {show:false,type:action.data.type,message:action.data.message}
  }
  return state;
}


export function raffleWinnersReducer(state = {type:'', data:{}}, action){
  let obj;
  switch (action.type) {
    case "RAFFLE_WINNERS_REQUEST":
      return  {type:'request', data: {}}
    case "RAFFLE_WINNERS_SUCCESS":
      return  {type:'success', data: action.data};
    case "DRAW_WINNER_SUCCESS":
      obj ={...state}
      obj.data.push(action.data)
      return obj;
    case "RAFFLE_WINNERS_ERROR":
      return  {type:'error', data: {}, message: action.message}
    case "RAFFLE_WINNERS_DELETE_SUCCESS":
      obj = {...state};
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        
        if(element.id == action.data.data.id){
          obj.data.splice(index,1);
        }
      }
      return obj;
    default:
      return {type:'',data:{}};
  }
}

export function userUpdateReducer(state = {type:"",data:{},message:[]}, action){
  let obj;
  switch (action.type) {
    case "ADMIN_USERS_UPDATE_RESET":
      return {type:"",data:{},message:""}
     case "ADMIN_USERS_UPDATE_REQUEST":
        return {type:"request",data:{},message:""} 
    case "ADMIN_USERS_UPDATE_SUCCESS":
      obj = {...state};
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id === action.data.id){
          obj.data[index] = element;
        }
      }
      return obj;
    case "ADMIN_USERS_UPDATE_ERROR":
      return {type:"error",data:{},message:action.messages}
    default:
      return state;
  }  
}

export function adminUsersDataReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "ADMIN_USERS_REQUEST":
      return {type:"request",data:[],message:""} 
    case "ADMIN_USERS_SUCCESS":
      return {type:"success",data:action.data,message:""} 
    case "ADMIN_USERS_CREATE_SUCCESS":
      obj ={...state}
      obj.data.push(action.data)
      return obj;
    case "ADMIN_USERS_UPDATE_SUCCESS":
      obj ={...state}
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id === action.data.id){
          obj.data[index] = action.data;
        }
      }
      return obj;
    case "ADMIN_USERS_ERROR":
      return {type:"error",data:[],message:action.messages}
    default:
      return state;
  }  
}


export function partnerFormReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "PARTNER_FORM_RESET":
      return {type:"",data:{},message:""}
     case "PARTNER_FORM_REQUEST":
        return {type:"request",data:{},message:""} 
    case "PARTNER_CREATE_SUCCESS":
      return {type:"success",data:action.data,message:[]}
    case "PARTNER_CREATE_ERROR":
      return {type:"error",data:{},message:action.messages}
    default:
      return state;
  }
}
export function partnersDataReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "PARTNERS_REQUEST":
      return {type:"request",data:[],message:""} 
    case "PARTNERS_SUCCESS":
      return {type:"success",data:action.data,message:""} 
    case "PARTNER_CREATE_SUCCESS":
      obj ={...state}
      obj.data.push(action.data)
      return obj;
    case "PARTNERS_UPDATE_SUCCESS":
      obj ={...state}
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id === action.data.id){
          obj.data[index] = action.data;
        }
      }
      return obj;
    case "PARTNERS_ERROR":
      return {type:"error",data:[],message:action.messages}
    default:
      return state;
  }  
}


export function categoryFormReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "CONTENT_CATEGORY_FORM_RESET":
      return {type:"",data:{},message:""}
     case "CONTENT_CATEGORY_FORM_REQUEST":
        return {type:"request",data:{},message:""} 
    case "CONTENT_CATEGORY_CREATE_SUCCESS":
      return {type:"success",data:action.data,message:[]}
    case "CONTENT_CATEGORY_CREATE_ERROR":
      return {type:"error",data:{},message:action.messages}
    default:
      return state;
  }
}

export function postFormReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "CONTENT_POST_FORM_RESET":
      return {type:"",data:{},message:""}
     case "CONTENT_POST_FORM_REQUEST":
        return {type:"request",data:{},message:""} 
    case "CONTENT_POST_CREATE_SUCCESS":
      return {type:"success",data:action.data,message:[]}
    case "CONTENT_POST_CREATE_ERROR":
      return {type:"error",data:{},message:action.messages}
    default:
      return state;
  }
}

export function categoriesDataReducer(state = {type:"",data:[],message:""}, action){
  let obj;
  switch (action.type) {
    case "CONTENT_CATEGORIES_REQUEST":
      return {type:"request",data:[],message:""} 
    case "CONTENT_CATEGORIES_SUCCESS":
      return {type:"success",data:action.data,message:""} 
    case "CONTENT_CATEGORY_CREATE_SUCCESS":
      obj ={...state}
      obj.data.push(action.data)
      return obj;
    case "CONTENT_POST_UPDATE_SUCCESS":
      obj ={...state}
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id === action.data.id){
          obj.data[index] = action.data;
        }
      }
      return obj;
    case "CONTENT_CATEGORY_DELETE_SUCCESS":
      let filteredData = JSON.parse(JSON.stringify(state.data))
      filteredData = filteredData.filter((item) => item.id != action.id)
      
      return {...state, data: filteredData}
    case "CONTENT_CATEGORIES_UPDATE_SUCCESS":
      obj ={...state}
      for (let index = 0; index < obj.data.length; index++) {
        const element = obj.data[index];
        if(element.id === action.data.id){
          obj.data[index] = action.data;
        }
      }
      return obj;
    case "CONTENT_CATEGORIES_ERROR":
      return {type:"error",data:[],message:action.messages}
    default:
      return state;
  }  
}