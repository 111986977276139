import React, {useEffect, useState} from 'react';
import { Modal, Box, Typography, TextField, Button,styled,InputAdornment,Tabs, Tab,FormControl, RadioGroup, FormControlLabel, Radio, Snackbar, Alert, InputLabel, OutlinedInput, Checkbox, Tooltip, Avatar } from '@mui/material';
import { connect } from 'react-redux';

import { updateRewardDrop,resetRewardCRD,setRewardCRD, setToast } from 'src/services/actions';
import CouponCode from '../DropTypeForm/couponCode';
import CloseIcon from '@mui/icons-material/Close';
import PublishingOptions from '../FormPart/PublishingOptions';
import UrlForm from '../DropTypeForm/urlForm';
import moment from 'moment-timezone';
import {  isValidHttpUrl, ValidateEmail, formatMomentObjectToDate, formatMomentObjectToTime, checkImageFileExt, validateImgDimension, handleImageForCompress } from 'src/utils';
import EventPassForm from '../DropTypeForm/event_pass';
import MerchandiseForm from '../DropTypeForm/merchandise';
import RaffleForm from '../DropTypeForm/raffle';
import {DateAndTimePicker, RewardDateAndTimePicker} from '../DateAndTimePicker';
import AllowList from '../DropTypeForm/allowList';
import Poll from '../DropTypeForm/poll'
import CustomRewardForm from '../DropTypeForm/custom';
import MemberSelectionForm from '../DropTypeForm/member_selection';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
};
const CustomTextField=styled(TextField)(
  ({theme})=> `
  fieldset{
    border-radius: 4px;
    }
  `
)
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ pt: 3, px: 1.5, height:'43vh ', overflowY: 'scroll' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const EditReward = (props) => {
  const { open, handleClose, setUpdateSuccessAlert } = props;
  const [value, setValue] = useState(0);
  const {editData} = props;
  const [nextTabErrors, setNextTabErrors] = useState({
    title: {val: editData.title, error: ""},
    details: {val: editData.details, error: ""},
    end_date: {val: editData.end_date, error: ""},
    end_time: {val: editData.end_date, error: ""},
    start_date: {val: editData.published_date, error: ""},
    start_time: {val: editData.published_date, error: ""},
    quantity: {val: editData.quantity, error: ""},
    code: {val: editData?.couponCodes?.code, error: ""},
    coupon_code_website: {val: editData?.couponCodes?.website, error: ""},
    max_winners: {val: editData?.raffle?.max_winners, error: ""},
    raffle_contact_email: {val: editData?.raffle?.contact_email, error: ""},
    content_url: {val: editData?.urls?.Url, error: ""},
    content_name: {val: editData?.urls?.ContentName, error: ""},
    merch_support_contact: {val: editData?.merchandise?.support_contact, error: ""},
    allow_list_price: {val: editData?.allowList?.price, error: ""},
    allow_list_website: {val: editData?.allowList?.project_website, error: ""},
    allow_list_disord_url: {val: editData?.allowList?.discord_url, error: ""},
    event_date: {val: editData?.eventPass?.event_date, error: ""},
    event_time: {val: editData?.eventPass?.event_date, error: ""},
    mint_date: {val: editData?.allowList?.mint_date, error: ""},
    mint_time: {val: editData?.allowList?.mint_date, error: ""},
    poll_question: {val: editData.poll?.question, error: ""},
    poll_choices: (editData.drop_type == 'poll' && editData.drop_type_data[0]?.choices?.length > 0) ? editData.drop_type_data[0]?.choices?.map((d_u) => {
      return(
          {choice: {value: d_u, error: ""}}
      )
    }) : [{choice: {value: "", error: ""}}, {choice: {value: "", error: ""}}],
    gatedContent: (editData.drop_type == 'gated_content' && editData.drop_type_data.length > 0) ? editData.drop_type_data.map((d_u) => {
      return(
          {content_url: {value: d_u.content_url, error: ""}, content_name: {value: d_u.button_text, error: ""}}
      )
    }) : [{content_url: {value: "", error: ""}, content_name: {value: "", error: ""}}]
  })
  const [formValues, setFormValues] = useState({
    title: editData.title,
    quantity: editData.quantity,
    end_date: editData.end_date == null || editData.end_date == "" ? '' : editData.end_date ,
    image: editData.image,
    status: editData.status,
    details: editData.details,
    unit_cost: editData.unit_cost,
    allow_reward_page: editData?.allow_reward_page ? editData.allow_reward_page : false,
    allow_embed_page: editData?.allow_embed_page ? editData.allow_embed_page : false,
    is_limited_quantity: editData?.is_limited_quantity ? editData.is_limited_quantity : false,
    is_one_time_use: editData?.is_one_time_use ? editData.is_one_time_use : false,
    schedule_now: editData?.is_schedule_now ? editData.is_schedule_now : false,
    is_coming_soon: editData?.is_coming_soon ? editData.is_coming_soon : false,
    start_date: editData?.published_date ? editData.published_date : '',
    drop_total_claim: editData?.drop_total_claim ? editData.drop_total_claim : 0,
    custom: editData?.drop_type_data ? {
      details: editData.drop_type_data[0]?.details
    } : {
      details: ""
    },
    couponCodes: editData?.drop_type_data ? {
      code: editData.drop_type_data[0]?.coupon_code ? editData.drop_type_data[0]?.coupon_code:"",
      website: editData.drop_type_data[0]?.website,
      details: editData.drop_type_data[0]?.details
    } : {
      code:"",
      website: "",
      details: ""
    },
    eventPass: editData?.drop_type_data ? {
      event_date: editData.drop_type_data[0]?.event_date ? editData.drop_type_data[0]?.event_date:"",
      website: editData.drop_type_data[0]?.website,
      details: editData.drop_type_data[0]?.details,
      unique_key: editData.drop_type_data[0]?.unique_key,
    } : {
      code:"",
      website: "",
      details: ""
    },
    merchandise: editData?.drop_type_data ? {
      details: editData.drop_type_data[0]?.details ? editData.drop_type_data[0]?.details:"",
      support_contact: editData.drop_type_data[0]?.support_contact ? editData.drop_type_data[0]?.support_contact:"",
      allow_shipping_info: editData.drop_type_data[0]?.allow_shipping_info,
      allow_size: editData.drop_type_data[0]?.allow_size,
    } : {
      details:"",
      support_contact: "",
      allow_shipping_info: true,
      allow_size: false
    }, 
    raffle: editData?.drop_type_data ? {
      max_winners: editData.drop_type_data[0]?.max_winners ? editData.drop_type_data[0]?.max_winners:"",
      registrant_message: editData.drop_type_data[0]?.registrant_message ? editData.drop_type_data[0]?.registrant_message:"",
      winner_message: editData.drop_type_data[0]?.winner_message,
      non_winner_message: editData.drop_type_data[0]?.non_winner_message,
      contact_email: editData.drop_type_data[0]?.contact_email,
    } : {
      max_winners:"",
      registrant_message: "",
      winner_message: "",
      non_winner_message: "",
      contact_email: ""
    },
    allowList: editData?.drop_type_data ? {
      project_name: editData.drop_type_data[0]?.project_name ? editData.drop_type_data[0].project_name : "",
      project_website: editData.drop_type_data[0]?.website ? editData.drop_type_data[0].website : "",
      price: editData.drop_type_data[0]?.price ? editData.drop_type_data[0].price : "",
      network: editData.drop_type_data[0]?.network ? editData.drop_type_data[0].network : "",
      discord_url: editData.drop_type_data[0]?.discord_url ? editData.drop_type_data[0].discord_url : "",
      mint_date : editData.drop_type_data[0]?.mint_date ? editData.drop_type_data[0].mint_date : "",
      description : editData.drop_type_data[0]?.description ? editData.drop_type_data[0].description : ""
    } : {
      project_name: "",
      project_website: "",
      price: "",
      network: "",
      discord_url: "",
      mint_date: "",
      description : ""
    },
    poll: editData.drop_type_data ? {
      question: editData.drop_type_data[0]?.question,
      choices: editData.drop_type_data[0]?.choices ? editData.drop_type_data[0]?.choices : ["", ""],
      allow_comment: editData.drop_type_data[0]?.allow_comment
    } : {
      question:"",
      choices: ["",""],
      allow_comment: ""
    },
    member_selection: editData.drop_type_data ? {
      choices: editData.drop_type_data[0]?.choices ? editData.drop_type_data[0]?.choices : ["", ""],
      allow_comment: editData.drop_type_data[0]?.allow_comment,
      allow_shipping_info: editData.drop_type_data[0]?.allow_shipping_info,
    } : {
      choices: ["",""],
      allow_comment: true,
      allow_shipping_info: true,
    },
    urls: editData?.drop_type_data ? editData.drop_type_data.map((d) => {
      return {
        'Url': d.content_url,
        'ContentName': d.button_text
      }
    }) : []
  });

  const [qtyDisabled, setQtyDisabled] = useState((!editData.is_limited_quantity));
  
  const [dtValue, setDtValue] = useState(formValues.end_date);
  const [dtPickerOpen, setDtPickerOpen] = useState(false);
  const [image, setImage] = useState(editData.image)
  const [embedErrorAlert, setEmbedErrorAlert] = useState(false)
  const [dropErrorAlert, setDropErrorAlert] = useState(false);
  const [startDtPickerOpen, setStartDtPickerOpen] = useState(false);
  const [scheduleLaterDisabled, setScheduleLaterDisabled] = useState((editData.is_schedule_now || editData.is_schedule_now === 'true'));
  // const [startDateValue, setStartDateValue] = useState(formatDateToDbSup(editData.published_date));
  const [startDateValue, setStartDateValue] = useState(formatMomentObjectToDate(editData.published_date));
  const [startTimeValue, setStartTimeValue] = useState(formatMomentObjectToTime(editData.published_date));
  const [endDateValue, setEndDateValue] = useState(formatMomentObjectToDate(formValues.end_date));
  const [endTimeValue, setEndTimeValue] = useState(formatMomentObjectToTime(formValues.end_date));

  const {rewardCRD} = props

  /* const handleFileSelect =(event)=>{
    handleData('image_remove', false);
    setImage(event.target.files[0])
  } */

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 388, 215, 388, 215).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 600x215";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
          .then((compressedDataURL) => {
            // console.log('Compressed Image Data URL:', compressedDataURL);
              handleData('image_remove', false);
              setImage(compressedDataURL)
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
      
    document.getElementById('file_upload').value = '';
  };

  useEffect(() => {
    if(image == null || image == '') {
      handleData('image_remove', true);
      handleData('image', '');
    } else {
      handleData('image_remove', false);
      handleData('image', image);
    }
  },[image])
  
  useEffect(()=>{
    props.resetRewardCRD();
  },[])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDtChange = (newValue) => {
    handleAddnValueData('end_date', newValue)
    setDtValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const removeImage =(e)=>{
    handleData('image_remove', true);
    setImage(null)
    document.getElementById('file_upload').value ="";
  }
  const triggerImage = (e) => {
   let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };


  const updateDrop = () => {
    if (formValues.title.trim() == ""){
      setNextTabErrors({...nextTabErrors, ['title']: {value: formValues.title, error: "Title can't be blank."}})
      setValue(0)
      return false;
    }
    if(dtValue && dtValue['$d'] == "Invalid Date") {
      setNextTabErrors({...nextTabErrors, ['end_date']: {value: formValues.end_date, error: "Invalid Date."}})
      setValue(0)
      return false;
    }

    if (formValues.details.trim().length > 1000){
      setNextTabErrors({...nextTabErrors, ['details']: {value: formValues.details, error: "Description cannot exceed 1000 words."}})
      setValue(0)
      return false;
    }
    if ((formValues.is_limited_quantity && (formValues.is_limited_quantity == true || formValues.is_limited_quantity == 'true'))){
      if(parseInt(formValues.quantity) < 1){
        setNextTabErrors({...nextTabErrors, ['quantity']: {value: formValues.quantity, error: "Please enter a quantity greater than 0"}});
        return false;
      }
      if(parseInt(formValues.quantity) > 100000000){
        setNextTabErrors({...nextTabErrors, ['quantity']: {value: formValues.quantity, error: "Quantity can't exceed 100000000."}});
        setValue(0)
        return false;
      }else if(formValues.quantity.trim() == ""){
        setNextTabErrors({...nextTabErrors, ['quantity']: {value: formValues.quantity, error: "Quantity can't be blank."}});
        setValue(0)
        return false;
      } else if(parseInt(formValues.quantity) < 0){
        setNextTabErrors({...nextTabErrors, ['quantity']: {value: formValues.quantity, error: "Quantity can't be negative."}});
        setValue(0)
        return false;
      }
    }
    if (editData.drop_type == 'coupon_code' && formValues.couponCodes.code === "" && formValues.couponCodes.code.trim() == ""){
      setNextTabErrors({...nextTabErrors, ['code']: {value: formValues.couponCodes.code, error: "Coupon Code can't be blank."}});
      setValue(1)
      return false;
    }
    if (editData.drop_type == 'coupon_code'  && formValues.couponCodes.website && formValues.couponCodes.website.trim() != "" && !isValidHttpUrl(formValues.couponCodes.website)) {
      setNextTabErrors({...nextTabErrors, ['coupon_code_website']: {value: formValues.couponCodes.code, error: "Enter valid URL."}});
      setValue(1)
      return false;
    }
    if (editData.drop_type == 'custom' && formValues.custom.details.trim() == "") {
      setNextTabErrors({ ...nextTabErrors, ["custom_details"]: { value: formValues.custom.details, error: "Details can't be blank" } });
      setValue(1)
      return false;
    }
    if (editData.drop_type == 'raffle'){
      if(formValues.raffle.max_winners == "") {
        setNextTabErrors({...nextTabErrors, ['max_winners']: {value: formValues.raffle.max_winners, error: "MAX number of winners can't be blank"}});
        setValue(1)
        return false;
      }
      if(parseInt(formValues.raffle.max_winners) < 1){
        setNextTabErrors({...nextTabErrors, ['max_winners']: {value: formValues.raffle.max_winners, error: "MAX number of winners must be greater than 0"}});
        return false;
      }
      if(parseInt(formValues.raffle.max_winners) > 999999999){
        setNextTabErrors({...nextTabErrors, ['max_winners']: {value: formValues.raffle.max_winners, error: "MAX number of winners can't exceed 999999999"}});
        setValue(1)
        return false;
      }

      
      if(!ValidateEmail(formValues.raffle.contact_email)){
        setNextTabErrors({...nextTabErrors, ['raffle_contact_email']: {value: formValues.raffle.contact_email, error: "Enter valid email address"}});
        return false;
      }
    }
    if(editData.drop_type == 'gated_content' && (formValues.urls.filter((u_d) => u_d.Url == "").length > 0 || formValues.urls.filter((u_d) => !isValidHttpUrl(u_d.Url) ).length > 0 || formValues.urls.filter((u_d) => u_d.ContentName == "").length > 0)){
      let filteredempty_urls = formValues.urls;
      for (let a = 0; a < filteredempty_urls.length; a++) {
        let err_h = {...nextTabErrors};
        if (filteredempty_urls[a].Url == ""){
          err_h['gatedContent'][a]['content_url'] = {value: filteredempty_urls[a].Url, error: "URL can't be blank."};
          setNextTabErrors(err_h);
        }

        if (!isValidHttpUrl(filteredempty_urls[a].Url)) {
          err_h['gatedContent'][a]['content_url'] = {value: filteredempty_urls[a].Url, error: "Enter valid URL."};
          setNextTabErrors(err_h);
        }

        if (filteredempty_urls[a].ContentName == ""){
          err_h['gatedContent'][a]['content_name'] = {value: filteredempty_urls[a].ContentName, error: "Content Name can't be blank."};
          setNextTabErrors(err_h);
        }
      }
      setValue(1)
      return false;
    }

    if(editData.drop_type == "poll"){
      let isError = false;
      let TabError = {...nextTabErrors}
      if(formValues.poll?.question == null || formValues.poll?.question.trim() == ""){
        TabError['poll_question'] = {val: formValues.poll.question, error: "Question can't be blank"}
        isError = true;

      }
      if(formValues.poll?.choices.filter((item) => item == "").length > 0){
        TabError['poll_choices'] = formValues.poll?.choices.map((choice) => {
          return (
            choice == "" ?
            {choice: {val: choice, error: "Choice can't be blank"}}
            :
            {choice: {val: choice, error: ""}}
          )
        })
        isError = true
      }

      if(isError){
        setValue(1)
        setNextTabErrors(TabError)
        return false;
      }
    }

    if(editData.drop_type == "member_selection"){
      let isError = false;
      let TabError = {...nextTabErrors};
      if(formValues.member_selection?.choices.filter((item) => item == "").length > 0){
        TabError['poll_choices'] = formValues.member_selection?.choices.map((choice) => {
          return (
            choice == "" ?
            {choice: {val: choice, error: "Choice can't be blank"}}
            :
            {choice: {val: choice, error: ""}}
          )
        })
        isError = true
      }

      if(isError){
        setValue(1)
        setNextTabErrors(TabError)
        return false;
      }
    }

    if (editData.drop_type == 'merchandise'){
      if(formValues.merchandise.support_contact == "") {
        setNextTabErrors({...nextTabErrors, ['merch_support_contact']: {value: formValues.merchandise.support_contact, error: "Support Email or Phone Number can't be blank"}})
        setValue(1)
        return false;
      }
    }

    if (editData.drop_type == 'allow_list'){
      let filtered_list_urls = formValues.allowList;
      if (filtered_list_urls.price != "" && parseFloat(filtered_list_urls.price) > 9999999) {
        setNextTabErrors({...nextTabErrors, ['allow_list_price']: {value: filtered_list_urls.price, error: "Price can't exceed 9999999"}})
        return false;
      }
      if (filtered_list_urls.project_website != "" && !isValidHttpUrl(filtered_list_urls.project_website)) {
        setNextTabErrors({...nextTabErrors, ['allow_list_website']: {value: filtered_list_urls.project_website, error: "Enter valid URL"}})
        setValue(1)
        return false;
      }
      if (filtered_list_urls.discord_url != "" && !isValidHttpUrl(filtered_list_urls.discord_url)) {
        setNextTabErrors({...nextTabErrors, ['allow_list_disord_url']: {value: filtered_list_urls.discord_url, error: "Enter valid URL"}})
        setValue(1)
        return false;
      }
    }
    if (!(formValues.allow_reward_page || formValues.allow_embed_page)) {
      setEmbedErrorAlert(true)
      setTimeout(() => {
        setEmbedErrorAlert(false);
      },5000);
      setValue(2)
      return false;
    }
    if (editData.drop_type == 'coupon_code') {
      formValues['drop_type_data'] = [formValues.couponCodes]
    } else if (editData.drop_type == 'gated_content') {
      formValues['drop_type_data'] = formValues.urls
    } else if (editData.drop_type == 'event_pass') {
      formValues['drop_type_data'] = [formValues.eventPass]
    } else if (editData.drop_type == 'merchandise') {
      formValues['drop_type_data'] = [formValues.merchandise]
    } else if (editData.drop_type == 'raffle') {
      formValues['drop_type_data'] = [formValues.raffle]
    } else if (editData.drop_type == 'allow_list') {
      formValues['drop_type_data'] = [formValues.allowList]
    } else if (editData.drop_type == 'poll') {
      formValues['drop_type_data'] = [formValues.poll]
    } else if (editData.drop_type == 'custom') {
      formValues['drop_type_data'] = [formValues.custom]
    } else if (editData.drop_type == 'member_selection') {
      formValues['drop_type_data'] = [formValues.member_selection]
    }
    if(formValues['image_remove'] == true){
      formValues['image'] = "";
    }
    if (editData.status == 1) {
      formValues['publish_now'] = true
    }
    if(formValues.schedule_now == "true" || formValues.schedule_now == true) {
      formValues['is_schedule_now'] = true
    }
    formValues['is_schedule_now'] = formValues.schedule_now
    setUpdateSuccessAlert(true)
    if (formValues.end_date == "Invalid date") {
      formValues['end_date'] = null
    }
    props.updateRewardDrop(formValues, editData.id)
  }
  useEffect(() => {
   if(props.rewardCRD.type == 'error' && dropErrorAlert == false){
      setDropErrorAlert(true);
      setTimeout(() => {
        setDropErrorAlert(false);
      },6000);
    }
  },[props.rewardCRD.type])
  const handleData = (type, val) => {
    let existing_data = {...formValues};
    existing_data[type] = val;
    setFormValues(existing_data);
  }

  const handleMultipleChilddata = (obj) => {
    let existing_data = {...formValues};
    for (let key in obj) {
      existing_data[key] = obj[key]
    }
    setFormValues(existing_data);
  }
  
  const handleAddnValueData = (type, val) => {
    if (type === 'is_limited_quantity' && val === false) {
      setQtyDisabled(true)
    } else {
      if (type === 'is_limited_quantity'){
        setQtyDisabled(false)
      }
    }
    // return
    handleData(type, val);
    setNextTabErrors({...nextTabErrors,[type]:{value: typeof val=="string" ?  val.trim() : val,error:""}});
  }
  const vertical =  'top'
  const horizontal = 'center'

  const sponsorSupplyCount = () => {
    let values = editData.sponsor_collections.map((item) => parseInt(item['total_supply']) || 0)
    let supplyCnt = values.reduce((a, b) => a + b, 0)

    return supplyCnt > 0 ? `(Up to ${supplyCnt})` : ''
  }
  const handleStartDtChange = (newValue) => {
    handleData('start_date', newValue)
    setStartDateValue(newValue);
    setNextTabErrors({...nextTabErrors,['start_date']:{value: null, error: ""}});
  };
  const handleSchedule = (type, val) => {
    if (type === 'schedule_now' && val === 'true') {
      setScheduleLaterDisabled(true)
    } else {
      setScheduleLaterDisabled(false)
    }
    handleData(type, val);
  }
  
  return (
    <Modal
      open={open}
      onClose={()=>{return (rewardCRD.type == "request" || rewardCRD.api_error) ? false : handleClose()}}
      aria-labelledby="Edit Reward"
      className='modals'
    >
      <Box sx={style} className='modal-card'>
        <Box sx={{px:3, pt:2}}>
          <Typography variant="h3" className='title'>Edit Reward</Typography>
        </Box>
       <Box sx={{py:2, px: 3, borderBottom: '1px solid #D0CFCF',background:'#F6F8FB' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          <Tab label={`Basic Info`} {...a11yProps(0)} />
          <Tab label={`Claim Details`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box sx={{px:3}}>
      <TabPanel value={value} index={0}>
        <Box sx={{'& .MuiBox-root.css-1y0gfdp': {padding:'10px !important'}}} className="textareArea">
          <TextField required fullWidth margin="normal" label="Title" value={formValues.title} onChange={(e) => handleAddnValueData('title', e.target.value)} name="title" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter a title.' error={nextTabErrors.title.error !== ""} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
          {nextTabErrors.title.error == '' ? '' : nextTabErrors.title.error}
          </Box>
          {formValues.title.length > 0 ? `${formValues.title.length} / 60` : false}
          </Box>} inputProps={{ maxLength: 60 }} FormHelperTextProps={{ style: { textAlign: (nextTabErrors.title.error !== "") ? 'left' : 'right', color: (formValues.title.length == 60 || nextTabErrors.title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} />
          
          <TextField fullWidth multiline rows={3} label="Description" value={formValues.details} onChange={(e) => handleAddnValueData('details', e.target.value)} margin="normal" name="short_description" placeholder="Enter a description." error={nextTabErrors.details.error !== ""} helperText={formValues.details.length > 0 ? (nextTabErrors.details.error == "") ? `${formValues.details.length} / 1000` : nextTabErrors.details.error : false} InputProps={{sx: {padding: "13px", "textarea": {p: "0px"}}}} FormHelperTextProps={{ style: { textAlign: (nextTabErrors.details.error !== "") ? 'left' : 'right', color: (formValues.details.length == 1000) ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} inputProps={{ maxLength: 1000 }}/>

          <TextField sx={{mt:2,  "& .Mui-disabled": {"-webkit-text-fill-color": "initial !important"}}} fullWidth variant="outlined" disabled placeholder={image ? "" :'Add an Image (Optional)'} onClick={triggerImage} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                  Browse... 
                  <input type="file" id="file_upload" name="banner" accept="image/png, image/jpeg, image/jpg, image/webp, image/gif, image/svg+xml" onChange={(e) => {
                    if (checkImageFileExt(e)){
                      handleFileSelect(e)
                    } else {
                      props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                    }
                  }} hidden  />
                </Button>
              </InputAdornment>
            ),
            startAdornment:(
              <InputAdornment position="start">
                <Box sx={{display:"flex"}}>
                  <Box sx={{position: 'relative'}} id="image_preview">
                    {image ?
                    <>
                    <img src={image} height="20" alt="" />
                    <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px',padding:'3px',minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                    </>
                    :
                    <>
                    <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                    </>
                  }
                  </Box>
                  <Typography variant="body1" ml={1}>{image?.name}</Typography>
                </Box>
              </InputAdornment>
            )
          , sx: {".MuiOutlinedInput-input": {paddingTop: '16px', paddingBottom: '16px'}}}} />

          <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "16px", mt:"20px"}}>Maximum Uses</Typography>

          <FormControlLabel
            sx={{ mt: 1, alignItems: 'start' }}
            control={
              <Checkbox
                disableRipple
                icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="100%" />}
                checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="100%" />}
                defaultChecked={formValues.is_limited_quantity}
                onChange={(e) => {
                  handleAddnValueData('is_limited_quantity', e.target.checked)
                }}
                sx={{pt:'0.1rem'}}
              />
            }
            label={
              <Box>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                  <Box component="span">Limit the number of times this reward can be used in total</Box>
                  <Tooltip title="This limits the total supply for this reward. After the limit is reached, the reward is completed and cannot be claimed."><Avatar src="/assets/img/info-icon.svg" alt="Info icon" style={{height:'16px',width:'16px', marginLeft: '5px'}}/></Tooltip>
                </Typography>
                {
                  !qtyDisabled &&
                  <Box width="35%">
                    <CustomTextField fullWidth margin="normal" type="number" value={(formValues.category =='raffle' && formValues.quantity == 0 ) ? "" :formValues.quantity} placeholder="Enter a number" label={<Box component="span">Amount&nbsp;<span style={{color:'red'}}>*</span></Box>} name="quantity" variant="outlined" InputLabelProps={{shrink: true}} error={nextTabErrors.quantity.error !== ""} helperText={nextTabErrors.quantity.error} onWheel={(e) => e.target.blur()} onChange={(e) => { handleAddnValueData('quantity', e.target.value) }}/>
                  </Box>
                }
              </Box>
            }
          />
          <FormControlLabel
            disabled={['allow_list', 'raffle', 'poll', 'event_pass', 'member_selection'].includes(editData.drop_type)}
            sx={{ mt: 1, alignItems: 'start', '&.Mui-disabled':{opacity: 0.5} }}
            control={
              <Checkbox
                disableRipple
                icon={<img alt="Uncheck" src="/assets/img/Uncheck.svg" width="100%" />}
                checkedIcon={<img alt="Check" src="/assets/img/Checkbox.svg" width="100%" />}
                checked={formValues.is_one_time_use}
                onChange={(e) => {
                  handleAddnValueData('is_one_time_use', e.target.checked)
                }}
                sx={{pt:'0.1rem'}}
              />
            }
            label={
              <Box>
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500, color: "#8D8B8B", display: 'flex', alignItems: 'center' }}>
                  <Box component="span">Limit to one use per person</Box>
                  <Tooltip title="This limits the reward to single use per person."><Avatar src="/assets/img/info-icon.svg" alt="Info icon" style={{height:'16px',width:'16px', marginLeft: '5px'}}/></Tooltip>
                </Typography>
              </Box>
            }
          />
          {/* <FormControl fullWidth> */}
            {/* <Typography variant="body2" sx={{color: "#000", fontFamily: "Roboto, sans-serif", fontWeight: "400", fontSize: "0.875rem", mt:2}}>Quantity <span style={{color: "rgb(237, 31, 35)"}}>*</span></Typography> */}
            {/* <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group"> */}
              {/* <FormControlLabel sx={{width:'100%', "& .MuiFormControlLabel-label": {width: "100%"}, mx:0}} onChange={(e) => {handleAddnValueData('is_limited_quantity', e.target.value)}} name="is_limited_quantity" control={<Radio disableRipple sx={{ml: '-11px', "&.Mui-checked": {color: "#504D4D"}, marginTop: nextTabErrors.quantity.error !== "" ? '-1.45rem' : 'inherit'}} checked={(formValues.is_limited_quantity == true || formValues.is_limited_quantity == 'true')} value={true} />} label={
                <CustomTextField disabled={qtyDisabled} fullWidth margin="normal" type="number" value={formValues.quantity} placeholder="How many?" label='Limited' name="quantity" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ min: 1, max: 100000000 }}  error={nextTabErrors.quantity.error !== ""} helperText={nextTabErrors.quantity.error}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  handleAddnValueData('quantity', e.target.value)
                }}/>
              } /> */}
              {/* <FormControlLabel onChange={(e) => {handleAddnValueData('is_limited_quantity', e.target.value)}} name="is_limited_quantity" control={<Radio disableRipple sx={{"&.Mui-checked": {color: "#504D4D"}}} checked={(formValues.is_limited_quantity == false || formValues.is_limited_quantity == 'false')} value={false} />} label={<Typography variant="body1" sx={{opacity: '0.5'}}>Unlimited <i>{sponsorSupplyCount()}</i></Typography>} /> */}
            {/* </RadioGroup> */}
          {/* </FormControl> */}

          {/* schedule area */}
          {
            editData.status == 1 && (!editData.is_schedule_now && moment(editData.published_date) > moment()) &&
            <>
              <Typography variant="body2" sx={{color: "#000", fontFamily: "General Sans Semibold",  fontSize: "0.875rem", mt:1}}>Schedule <span style={{color: "rgb(237, 31, 35)"}}>*</span></Typography>
              <FormControlLabel onChange={(e) => {handleSchedule('schedule_now', e.target.value)}} name="schedule_publish_now" control={<Radio disableRipple sx={{"&.Mui-checked": {color: "#504D4D"}}} checked={formValues.schedule_now} value={true} />} label={ <Typography variant="body1" sx={{opacity: '0.5'}}>Publish Now</Typography>} />
              <FormControlLabel sx={{width:'100%', "& .MuiFormControlLabel-label": {width: "100%"}, mx:0}} onChange={(e) => {handleSchedule('schedule_now', e.target.value)}} name="schedule_publish_now" control={<Radio disableRipple sx={{ml: '-11px', "&.Mui-checked": {color: "#504D4D"}, marginTop: nextTabErrors.quantity.error !== "" ? '-1.45rem' : 'inherit'}} checked={!formValues.schedule_now} value={false} />} label={
                <Box>
                  <DateAndTimePicker formtype="edit" dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type="start_date" handleChildData={handleData} startDateValue={startDateValue} setStartDateValue={setStartDateValue} startTimeValue={startTimeValue} setStartTimeValue={setStartTimeValue} timeWidth="8rem" dateWidth="19rem" />
                </Box>
              } />
              <FormControlLabel disabled={formValues.schedule_now} sx={{"& .MuiFormControlLabel-label": {opacity: '0.5'}, ml: "16px", mt: "5px"}} onChange={(e) => {handleAddnValueData('is_coming_soon', e.target.checked)}} checked={formValues.is_coming_soon} control={<Checkbox defaultChecked disableRipple icon={<img src="/assets/img/Uncheck.svg" width="18" alt="" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="18" alt="" />}  />} label={`Display as “coming soon” on ${editData.community_type === 'membership' ? 'Membership Page' : 'Community Page'}`} />
            </>
          }

          <Box sx={{my:2}}>
            <RewardDateAndTimePicker formtype="edit" dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} picker_type={["end_date", "end_time"]} suffix_name="End" handleChildData={handleDtChange} startDateValue={endDateValue} setStartDateValue={setEndDateValue} startTimeValue={endTimeValue} setStartTimeValue={setEndTimeValue} timeWidth="10rem" dateWidth="50rem" />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
      {
        (() => {
          if (editData.drop_type == 'event_pass'){
            return (
              <EventPassForm handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if (editData.drop_type == 'gated_content'){
            return (
              <UrlForm handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if(editData.drop_type == 'coupon_code') {
            return (
              <CouponCode handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          }else if(editData.drop_type == 'merchandise') {
            return (
              <MerchandiseForm  handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          }else if(editData.drop_type == 'raffle') {
            return (
              <RaffleForm  handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if (editData.drop_type == 'allow_list') {
            return (
              <AllowList handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if (editData.drop_type == 'poll') {
            return (
              <Poll handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if (editData.drop_type == 'custom') {
            return (
              <CustomRewardForm handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          } else if (editData.drop_type == 'member_selection') {
            return (
              <MemberSelectionForm handleChildData={handleData} dropData={formValues} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            )
          }
        })()
      }
      </TabPanel>
      </Box>
      {
        (rewardCRD.type == "request") ? 
        <Box sx={{ p:3}} display="flex" justifyContent="space-between">
          <Button color="primary" onClick={handleClose} sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem'}} className="font-weight-600">Cancel</Button>
          <Button variant="contained" color="primary" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',opacity: '0.5', cursor:'not-allowed'}} className="font-weight-600">Updating...</Button>
        </Box>
        :
        <Box sx={{ p:3}} display="flex" justifyContent="space-between">
          <Button color="primary" onClick={handleClose} sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem'}} className="font-weight-600">Cancel</Button>
          <Button variant="contained" color="primary"   sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem'}} onClick={updateDrop} className="font-weight-600">Update</Button>
        </Box>
      }

        <Snackbar sx={{zIndex: '7 !important', width:'522px'}} anchorOrigin={{ vertical, horizontal }} open={embedErrorAlert} autoHideDuration={5000} key={vertical + horizontal}>
          <Alert severity="error" sx={{ width: '100%' }}>Please select at least one publishing option</Alert>
        </Snackbar>
        {(rewardCRD.api_error && rewardCRD.message!="") &&
          <Snackbar sx={{zIndex: '7 !important', width:'522px'}} anchorOrigin={{ vertical, horizontal }} open={dropErrorAlert} autoHideDuration={5000} key={vertical + horizontal}>
            <Alert severity="error" sx={{ width: '100%' }}>{rewardCRD.message}</Alert>
          </Snackbar>
        }
      </Box> 
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
})

const mapDispatchToProps = {
  updateRewardDrop,
  resetRewardCRD,
  setRewardCRD,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(EditReward)