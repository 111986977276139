import React, {useEffect, useState} from 'react';
import { Modal,Box, Button,Typography, Card, Autocomplete, TextField, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { connect } from 'react-redux';
import { alphaHex } from 'src/utils';
import { RewardTypePill } from '../RewardsData/reward_type_pill';
import countries from "../../services/constants/countries.json"

  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius:"8px",
    maxHeight: "87.5vh",
    overflowY: "auto"
  };

function PollPreview(props) {
  const webkitStyleSettings = {
    WebkitBoxShadow: "0 0 0 100px #f6f8fb inset",
    WebkitTextFillColor: "rgba(34, 51, 84, 1)",
  };

  const { open, handleClose, reward, info, user} = props;
  const [selectedOption, setSelectedOption] = useState('')
  const [basePmColor1, setBasePmColor1] = useState('rgba(26, 117, 255, 0.1)')
  const [basePmColor2, setBasePmColor2] = useState('rgba(26, 117, 255, 0.4)')
  // console.log("user =>",alphaHex(user.data.brand_data.primary))

  useEffect(() => {
    const color1 = alphaHex(`#${user.data.brand_data.primary}`, 0.1)
    const color2 = alphaHex(`#${user.data.brand_data.primary}`, 0.4)
    setBasePmColor1(color1)
    setBasePmColor2(color2)
  },[])

  return (
    <Box className='preview-modal'>
      <Box textAlign="center">
        <RewardTypePill reward={reward} />
      </Box>
        <Typography variant="h6" className="modal-title">Please complete this form to claim reward</Typography>

        <Card sx={{ border: '1px solid #CCCEDD', bgcolor: "#F6F8FB", marginTop: "19px", px: 4, mx: 2, boxShadow:'0px 4px 21px rgba(24, 69, 136, 0.2)',borderRadius: "6px"}}>
          <Box sx={{ background: 'rgba(34, 51, 84, 0.02)', borderRadius: '8px', p:2, mt:2}}>
            <Box mt={3}>
              <FormControl error={false}>
                <Typography color="#000" className='font-gs-sb'>Select your Choice
                  <Typography component="span" color="#db3131">*</Typography>
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                
                  {info.choices.map((c_data, index) => {
                    return (
                      <FormControlLabel sx={{'& .MuiFormControlLabel-label': {color: "#000"}}} value={c_data} control={<Radio onChange={(e) => {}} />} label={c_data} />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>

            {info.allow_shipping_info &&
                  <Box>
                    <TextField
                      autoComplete="off"
                      error={false}
                      helperText={false}
                      FormHelperTextProps={{ style: { fontWeight: "400", fontSize: "9px" } }}
                      size="small"
                      className="merch-form"
                      sx={{ mb: 0.7 }}
                      fullWidth
                      margin="normal"
                      name="address_line_1"
                      onChange={(e) => {}}
                      label="Address Line 1"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Street address, P.O. box, company name, c/o"
                      required
                      inputProps={{ maxLength: 130, style: webkitStyleSettings }}
                    />

                    <TextField autoComplete="off" size="small" className="merch-form" sx={{ mb: 0.7 }} fullWidth margin="normal" onChange={(e) => {}} name="address_line_2" label="Address Line 2" variant="outlined" InputLabelProps={{ shrink: true }} placeholder="Apartment, suite, unit, floor, etc." inputProps={{ maxLength: 130, style: webkitStyleSettings }} />

                    <TextField
                      autoComplete="off"
                      error={false}
                      helperText={false}
                      FormHelperTextProps={{ style: { fontWeight: "400", fontSize: "9px" } }}
                      size="small"
                      className="merch-form"
                      sx={{ mb: 0.7 }}
                      fullWidth
                      margin="normal"
                      onChange={(e) => {}}
                      name="city"
                      label="City"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                      inputProps={{ maxLength: 130, style: webkitStyleSettings }}
                    />

                    <TextField
                      autoComplete="off"
                      error={false}
                      helperText={false}
                      FormHelperTextProps={{ style: { fontWeight: "400", fontSize: "9px" } }}
                      size="small"
                      className="merch-form"
                      sx={{ mb: 0.7 }}
                      fullWidth
                      margin="normal"
                      onChange={(e) => {}}
                      name="state"
                      label="State/Province/Region"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                      inputProps={{ maxLength: 130, style: webkitStyleSettings }}
                    />

                    <TextField
                      autoComplete="off"
                      error={false}
                      helperText={false}
                      FormHelperTextProps={{ style: { fontWeight: "400", fontSize: "9px" } }}
                      size="small"
                      className="merch-form"
                      sx={{ mb: 0.7 }}
                      fullWidth
                      margin="normal"
                      onChange={(e) => { }}
                      name="zip"
                      label="ZIP/ Postal Code"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      required
                      inputProps={{ maxLength: 130, style: webkitStyleSettings }}
                    />

                    <FormControl className="merch-form" sx={{ my: 1.4 }} fullWidth>
                      <Autocomplete
                        disableClearable={true}
                        onChange={(e, value) => {}}
                        disablePortal
                        size="small"
                        id="combo-box-demo"
                        options={countries}
                        sx={{ ".MuiAutocomplete-popupIndicator": { backgroundColor: "none" }, ".MuiAutocomplete-input[value^='Choose a country']": { color: "#8c96a8", WebkitBoxShadow: "0 0 0 100px #f6f8fb inset", WebkitTextFillColor: "rgba(34, 51, 84, 0.5)" } }}
                        renderInput={(params) => <TextField placeholder="Choose a country" required autoComplete="off" error={false} helperText={false} FormHelperTextProps={{ style: { fontWeight: "400", fontSize: "9px" } }} {...params} InputLabelProps={{ shrink: true }} label="Country" />}
                      />
                    </FormControl>
                  </Box>
                  }
              {info?.allow_comment &&
              <Box className='textareArea'>
                <TextField fullWidth multiline rows={3} margin="normal" label={<span>ADD A COMMENT</span>} name="comment" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Your comments will not be made public.'  />
              </Box>
              }

            <Button variant='contained' color='primary' className='confirm-btn'>Submit</Button>

          </Box>
        </Card>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PollPreview)