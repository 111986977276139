import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, Grid, Skeleton, CardMedia, Avatar, Tooltip, Link, Snackbar, Alert, Chip } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";

import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { converDateToRemainingDays, convertToInternationalCurrency, createIcsFile, dropTypesData, gaEvent, getSocialIcon, socialActivities, viewDate } from "src/utils";
import moment from "moment";
import EndReward from "src/components/Modals/end_reward";
import TimerView from "src/components/TimerView";
import DeleteReward from "src/components/Modals/delete_reward";
import EditReward from "src/components/Modals/edit_reward";
import CheckinAttendees from "src/components/Modals/checkin_attendees";
import DrawWinners from "src/components/Modals/draw_winners";
import PollResultModal from "src/components/Modals/pollResultData";
import NotifyRaffleWinner from "src/components/Modals/notify_winner";
import { rewardClaimedData, setToast, duplicateRewardDrop, getRaffleWinners } from "src/services/actions";
import DefaultLayout from "src/components/RewardCategoryPreview/layout";
import CouponCodeClaimed from "src/components/RewardCategoryPreview/coupon_code";
import GatedContentPreview from "src/components/RewardCategoryPreview/gated_content";
import EventPassPreview from "src/components/RewardCategoryPreview/event_pass";
import MerchandisePreview from "src/components/RewardCategoryPreview/merchandise";
import RafflePreview from "src/components/RewardCategoryPreview/raffle";
import PollPreview from "src/components/RewardCategoryPreview/poll";
import MemberSelectionPreview from "src/components/RewardCategoryPreview/member_selection";
import AllowListPreview from "src/components/RewardCategoryPreview/allow_list";
import CustomRewardPreview from "src/components/RewardCategoryPreview/custom";
import { TraitPill } from "src/components/FormPart/TraitPill";
import { RewardTypePill } from "src/components/RewardsData/reward_type_pill";

const DropDetail = (props) => {
  const vertical = "top";
  const horizontal = "center";
  const { id } = useParams();
  const { page, user, rewardList, rewardCRD, setShowSentAlert, raffleWinners } = props;

  // const {dropsData, setOpenCopiedBar, setUpdatedRewardAlert, setShowSentAlert} = props
  const navigate = useNavigate();
  const reward = rewardList.data.find((r) => r.id === id);

  const [openCopiedBar, setOpenCopiedBar] = useState(false);
  const [endReward, setEndReward] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [showEndTime, setShowEndTime] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateSuccessAlert, setUpdateSuccessAlert] = useState(false);
  const [checkInEvent, setCheckInEvent] = useState(false);
  const [drawRaffleWinner, setDrawRaffleWinner] = useState(false);
  const [pollResult, setPollResult] = useState(false);
  const [notifyWinner, setNotifyWinner] = useState(false);
  const [notifyRaffleObj, setNotifyRaffleObj] = useState({});
  const [collectionTraits, setCollectionTraits] = useState([]);
  const [reactionsData, setReactionsData] = useState([
    {"reaction":"love","reactionCnt":"0"},
    {"reaction":"happy","reactionCnt":"0"},
    {"reaction":"neutral","reactionCnt":"0"},
    {"reaction":"thinking","reactionCnt":"0"},
    {"reaction":"unhappy","reactionCnt":"0"}
  ]);
  const handleOpen = () => setOpen(true);
  const ref = useRef(null);

  const handleClose = () => {
    setOpenPopover(false);
  };
  useEffect(() => {
    if (reward.status !== 2 && reward.end_date) {
      let value = converDateToRemainingDays(reward.end_date);
      setShowEndTime(value);
      let interVal = setInterval(() => {
        let value = converDateToRemainingDays(reward.end_date);
        setShowEndTime(value);
        if (value == "") {
          props.rewardCompleted(reward.id);
          clearInterval(interVal);
        }
      }, 1000);

      return () => {
        clearInterval(interVal);
      };
    }
  }, [reward?.end_date]);

  useEffect(() => {
    reward.drop_type === "raffle" && props.getRaffleWinners(id);
  }, []);

  useEffect(() => {
    if (rewardCRD.type == "success") {
      setOpen(false);
      setDeleteModal(false);
      setEndReward(false);
      // navigate('/drops/all', { replace: true });
    } else if (rewardCRD.type == "error") {
      if (endReward) {
        props.setToast(true, { type: "end_error", message: rewardCRD.message });
        setEndReward(false);
      }
    }
  }, [rewardCRD.type]);

  useEffect(() => {
    const traitsData = reward.sponsor_collections.filter((c) => c.traits !== null);
    const modTraits = traitsData
      .map((t) => {
        const trKeys = Object.keys(t.traits);
        const trVals = Object.values(t.traits);

        return trVals
          .map((s, i) => {
            return s.map((n_s) => {
              return {
                logo_image: t.logo_image,
                trait: trKeys[i],
                value: n_s,
              };
            });
          })
          .flat(1);
      })
      .flat(1);
    setCollectionTraits(modTraits);
  }, []);

  useEffect(() => {
    if (reward.reward_reactions){
    let arr1 = reactionsData
    let arr2 = reward.reward_reactions
    
    const result = arr1.map(v => ({ ...v, ...arr2.find(sp => sp.reaction === v.reaction) }));

    setReactionsData(result)
    }
  },[reward.reward_reactions])

  const allowDuplicateRewardDrop = async () => {
    const new_drop = await props.duplicateRewardDrop(reward.id);
    if (new_drop.type == "success") {
      gaEvent({
        category: `Reward duplicated - ${reward.id}`,
        label: `-${user.data.eth_address} -`,
        value: reward.id,
        action: "admin_reward_duplicate",
      });

      navigate(`/rewards/${new_drop.data.community_type === 'membership' ? 'membership' : 'community'}/edit`, { state: { reward_drop_data: new_drop.data, is_duplicate: true } });
    }
  };

  const claimDataDownload = async () => {
    if (reward.drop_total_claim == 0) {
      return false;
    }
    const claimData = await rewardClaimedData(reward);
  };

  const copyEmbeddedCode = () => {
    let embeddedCode = `<iframe src="${user.data.full_brand_domain}/widgets/${reward.id}" height="650" width="800" style="border:0px"></iframe>`;
    navigator.clipboard.writeText(embeddedCode);
    handleClose();
    props.setToast(true, { type: "success", message: "Copied to clipboard" });
  };

  return (
    <Box
      className="scrollbar-hide rewards-section"
      width={"calc(100% - 259px)"}
      sx={{ padding: "40px", height: "calc(99vh - 80px)", overflowY: "auto", boxSizing: "border-box" }}
    >
      
      <Box sx={{ pb: 1, pt: 5 }} display="flex" justifyContent="space-between" alignItems="center">
        <Box component="span"></Box>
        <Box>
          {reward.status === 1 && reward.drop_type == "event_pass" && moment() > moment(reward.published_date) && (
            <Button
              onClick={() => {
                setCheckInEvent(true);
                setOpenPopover(false);
              }}
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
            >
              Check in attendees
            </Button>
          )}

          {moment() > moment(reward.published_date) && reward.status === 1 && (
            <Button
              onClick={() => {
                setEndReward(true);
                setOpenPopover(false);
              }}
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
            >
              End Now
            </Button>
          )}

          {reward.status === 0 && (
            <RouterLink to={`/rewards/${reward.community_type === 'membership' ? 'membership' : 'community'}/edit`} state={{ reward_drop_data: reward }}>
              <Button variant="outlined" className="btn" sx={{ ml: 1.5, mb: 2 }}>
                Edit & Publish
              </Button>
            </RouterLink>
          )}

          {reward.status === 1 && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                handleOpen();
                setOpenPopover(false);
              }}
            >
              Edit
            </Button>
          )}

          {(reward.status === 2 || reward.status === 1) && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                allowDuplicateRewardDrop();
                setOpenPopover(false);
              }}
            >
              Duplicate
            </Button>
          )}

          {reward.drop_type == "poll" && (reward.status === 2 || reward.status === 1) && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                setPollResult(true);
                setOpenPopover(false);
              }}
            >
              View Poll Results
            </Button>
          )}
          {reward.drop_type == "raffle" && reward.status === 2 && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                setDrawRaffleWinner(true);
                setOpenPopover(false);
              }}
            >
              Draw Raffle Winners
            </Button>
          )}

          {moment() > moment(reward.published_date) && reward.drop_total_claim > 0 && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                claimDataDownload();
                setOpenPopover(false);
              }}
            >
              Download Results
            </Button>
          )}

          {(reward.status === 0 || reward.status === 2) && (
            <Button
              variant="outlined"
              className="btn"
              sx={{ ml: 1.5, mb: 2 }}
              onClick={() => {
                setDeleteModal(true);
                setOpenPopover(false);
              }}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ p: "50px", border: 1, boxShadow: "0px 2px 2px 0px rgba(159, 162, 191, 0.32), 0px 9px 16px 0px rgba(159, 162, 191, 0.18)" }}>
        <Box className="step3RewardDetail">
          <Grid container spacing={4}>
            <Grid item lg={3.5} sm={3.5} xl={3.5} md={3.5} xs={3.5} sx={{position: 'relative'}}>
              {
                reward.redeem_points !== null &&
                <Chip sx={{position: 'absolute', borderRadius: '8px', ml: '10px', mt: '10px', background: 'rgba(43, 32, 17, 0.75)', fontSize: '13px'}} className="font-gs-sb" label={`${reward.redeem_points} pts`} />
              }
              {reward.image == null ? (
                <Box width="100%" height="400px" sx={{ bgcolor: "#FFEBEB", borderRadius: "15px", border: "1px solid rgba(0, 0, 0, 0.20)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src="/assets/img/img-icon.svg" alt="Img Icon" width="67" />
                </Box>
              ) : (
                <CardMedia sx={{border: "1px solid rgba(0, 0, 0, 0.20)", borderRadius: "15px"}} className="image-com" component="img" image={reward.image} alt={reward.title} />
              )}
              {
                reward.reward_reactions?.length > 0 &&
                <Box mt="10px">
                  <Grid container spacing="17.5px" justifyContent="center" alignItems="start">
                    {reactionsData.map((item,index) => {
                      return (
                        <Grid item key={index} sx={{display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'start'}}>
                          <Box component="span">
                            <img src={`/icons/react-${item.reaction}.svg`} width="20" height="20" alt={item.reaction} />
                          </Box>
                          <Box component="span">{item.reactionCnt !== '0' ? item.reactionCnt : ' '}</Box>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              }
            </Grid>
            <Grid item lg={8.5} sm={8.5} xl={8.5} md={8.5} xs={8.5}>
              <Box>
                {reward.title ? (
                  <Typography variant="h1" className="title" sx={{mt: '0 !important', textOverflow: "ellipsis", wordBreak: "break-all"}}>
                    {reward.title}
                  </Typography>
                ) : (
                  <Typography variant="h1" className="title" sx={{ color: "#8D8B8B !important" }}>
                    (No title defined)
                  </Typography>
                )}

                {reward.details ? (
                  <Typography variant="body1" className="description">
                    {reward.details}
                  </Typography>
                ) : (
                  <Typography variant="body1" className="description">
                    Description: n/a
                  </Typography>
                )}

                

                {
                  reward.community_type === 'membership' ? 
                  <Box mt="1.25rem" width="130px">
                    <Typography variant="body1" className="collectnTitle" mb="20px">
                      Available To:
                    </Typography>
                    <Box className="membership-pill" border="1px solid" p="7px" borderRadius="1000px">
                      <Box display="flex" alignItems="center">
                        <Avatar src={reward.sponsor_collections[0]?.logo_image ?reward.sponsor_collections[0]?.logo_image: '/assets/images/default_collection.svg'} alt={reward.sponsor_collections[0]?.title} style={{height: '25px', width: '25px', borderRadius: '50%'}}/>
                        <Typography variant="body2" className='sponsor-name'>All Members</Typography>
                      </Box>
                    </Box>
                  </Box>
                  :
                reward.sponsor_collections.length > 0 && (
                  <Box mt="1.25rem">
                    <Typography variant="body1" className="collectnTitle">
                      Available To:
                    </Typography>
                    <Box mt="1.25rem" display="flex" alignItems="center">
                      {reward.sponsor_collections.map((item, index) => {
                        return (
                          <Box key={index} display="flex" alignItems="center" mr={2}>
                            <Avatar src={item.logo_image !== null && item.logo_image !== "" ? item.logo_image : "/assets/images/default_collection.svg"} alt={item.title} className="sponsorcol_image" />
                            <Typography variant="body1" className="collectnIndvTitle">
                              {item.title}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}

                {collectionTraits.length > 0 && (
                  <Box sx={{ mt: "1.25rem" }}>
                    {collectionTraits.map((ct) => {
                      return <TraitPill collectionTrait={ct} />;
                    })}
                  </Box>
                )}

                <Box mt="1.25rem" className="impDt">
                  <Grid container spacing={2}>
                    <Grid item lg={1.65}>
                      <Typography variant="body1">Claimed</Typography>
                      <Typography variant="h6" className="claimed-limit">
                        {reward.drop_total_claim}
                        {/* {reward.is_limited_quantity && <Box component="span">{reward.quantity ? ` of ${convertToInternationalCurrency(reward.quantity)}` : ""}</Box>} */}
                      </Typography>
                    </Grid>

                    <Grid item lg={2}>
                      <Typography variant="body1">Status</Typography>
                      {(() => {
                        if (reward.status === 1) {
                          return <Typography variant="h6" className="started-time">{moment(reward.published_date) > moment() ? "Scheduled" : "Active"}</Typography>;
                        } else if (reward.status === 2) {
                          return <Typography variant="h6" className="started-time">Completed</Typography>;
                        } else {
                          return <Typography variant="h6" className="started-time">Draft</Typography>;
                        }
                      })()}
                    </Grid>

                    <Grid item lg={2}>
                      <Typography variant="body1">{reward.status !== 0 || !reward.is_schedule_now ? (moment() > moment(reward.published_date) ? "Started" : "Starts In") : "Started"}</Typography>
                      {reward.status !== 0 || !reward.is_schedule_now ? (
                        <Typography variant="h6" className="started-time">
                          {reward.status !== 0 || !reward.is_schedule_now ? (
                            moment() > moment(reward.published_date) ? (
                              viewDate(reward.published_date)
                            ) : (
                              <Box>
                                <Tooltip
                                  placement="bottom-start"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "rgba(34, 51, 84, 1)",
                                        width: "130px",
                                        "& :before": {
                                          color: "rgba(34, 51, 84, 1)",
                                        },
                                      },
                                    },
                                  }}
                                  title={
                                    <Typography variant="body2" align="center" sx={{ fontSize: "15px", fontFamily: "Roboto, sans-serif", fontWeight: "400", color: "rgba(255,255,255,0.7)", zIndex: 100, position: "relative" }}>
                                      Add to calendar
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <Box display="flex">
                                    <label>
                                      <Typography variant="span" sx={{ color: "#0BB681", cursor: "pointer" }} onClick={() => createIcsFile(reward, "start_date")}>
                                        <TimerView viewDate={reward.published_date} loadList={props.loadList} />
                                      </Typography>
                                    </label>
                                  </Box>
                                </Tooltip>
                              </Box>
                            )
                          ) : (
                            ""
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="h6" className="starts-time">
                          -
                        </Typography>
                      )}
                    </Grid>

                    {reward.status !== 2 && reward.end_date && showEndTime != "" ? (
                      reward.status == 1 ? (
                        <Grid item lg={2}>
                          <Tooltip
                            placement="bottom-start"
                            componentsProps={{ tooltip: { sx: { backgroundColor: "rgba(34, 51, 84, 1)", width: "130px", "& :before": { color: "rgba(34, 51, 84, 1)" } } } }}
                            title={
                              <Typography variant="body2" align="center" sx={{ fontSize: "15px", fontFamily: "Roboto, sans-serif", fontWeight: "400", color: "rgba(255,255,255,0.7)", zIndex: 100, position: "relative" }}>
                                Add to calendar
                              </Typography>
                            }
                            arrow
                          >
                            <Box display="flex" onClick={() => createIcsFile(reward, "end_date")}>
                              <label>
                                <Box sx={{ cursor: "pointer" }}>
                                  <Typography variant="body1">Ends</Typography>
                                  <Typography variant="h6" className="ends-time">
                                    {showEndTime}
                                  </Typography>
                                </Box>
                              </label>
                            </Box>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid item lg={2}>
                          <Typography variant="body1">Ends</Typography>
                          <Typography variant="h6" className="ends-time">
                            {showEndTime}
                          </Typography>
                        </Grid>
                      )
                    ) : (
                      reward.status == 0 && (
                        <Grid item lg={2}>
                          <Typography variant="body1">Ends</Typography>
                          <Typography variant="h6" className="ends-time">
                            -
                          </Typography>
                        </Grid>
                      )
                    )}
                    {reward.status == 2 && (
                      <Grid item lg={2}>
                        <Typography variant="body1">Ends</Typography>
                        <Typography variant="h6" className="ends-time">
                          {viewDate(reward.drop_end_time)}
                        </Typography>
                      </Grid>
                    )}
                    
                    <Grid item lg={2}>
                      <Typography variant="body1">Max Uses (Total)</Typography>
                      {
                        reward.is_limited_quantity ?
                        <Typography variant="h6" className="started-time">{reward.quantity}</Typography>
                        :
                        <Typography variant="h6" className="started-time">Unlimited</Typography>
                      }
                    </Grid>

                    <Grid item lg={2.35}>
                      <Typography variant="body1">Max Uses (Per Person)</Typography>
                      {
                        reward.is_one_time_use ?
                        <Typography variant="h6" className="started-time">1</Typography>
                        :
                        <Typography variant="h6" className="started-time">Unlimited</Typography>
                      }
                    </Grid>
                  </Grid>
                </Box>
                {reward.drop_type === "raffle" && (
                  <Box display="flex" mt="1.25rem" className="statusbox">
                    <Typography className="heading">Max Number Of Winners:</Typography>&nbsp;<Typography className="non-heading">{reward.drop_type_data[0]?.max_winners}</Typography>
                  </Box>
                )}

                {reward.claim_condition.length > 0 && reward.claim_condition_message !== null && (
                  <Box mt="1.25rem">
                    <Typography variant="body1" className="taskTitle">
                      Required Tasks
                    </Typography>
                    <Grid container spacing={2} className="tasksBox" mt="0.1rem">
                      {Object.values(reward.claim_condition_message).map((social_item, index) => {
                        let s_key = Object.keys(reward.claim_condition_message)[index];
                        let taskObj = Object.values(socialActivities)
                          .flat(1)
                          .filter((a) => s_key.includes(a.key_name))[0];
                        if (social_item !== "" && taskObj) {
                          return (
                            <Grid item lg={6} sm={6} md={6} xl={6}>
                              <Box display="flex" bgcolor="#F3F3F3" p="15px" borderRadius="10px" alignItems="center">
                                <Box mr={1}>
                                  <Avatar sx={{ bgcolor: "white" }}>{getSocialIcon(taskObj?.parent)}</Avatar>
                                </Box>

                                <Box display="flex" flexDirection="column">
                                  <Typography className="head">
                                    {taskObj?.heading} on {taskObj?.parent}
                                  </Typography>

                                  {s_key.includes("twitter_follow") ? (
                                    <Typography className="subhead" color="#AEACAC" display="flex">
                                      Follow account "
                                      <Link target="_blank" href={`https://twitter.com/${social_item}`}>
                                        {social_item}
                                      </Link>
                                      "
                                    </Typography>
                                  ) : (
                                    <Typography className="subhead" color="#AEACAC" display="flex">
                                      {taskObj?.heading} "
                                      <Link target="_blank" href={typeof social_item === "object" && taskObj.parent === "Discord" ? social_item?.server_url : social_item}>
                                        {taskObj?.link_text}
                                      </Link>
                                      "
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                          );
                        }
                      })}

                      {reward.claim_condition.includes("email") && (
                        <Grid item lg={6} sm={6} md={6} xl={6}>
                          <Box display="flex" bgcolor="#F3F3F3" p="15px" borderRadius="10px" alignItems="center">
                            <Box mr={1}>
                              <Avatar sx={{ bgcolor: "white" }}>
                                <img src="/assets/img/email-claim.svg" alt="email-sm" />
                              </Avatar>
                            </Box>
                            <Box display="flex" flexDirection="column">
                              <Typography className="head">Enter an email address</Typography>
                              <Typography className="subhead" color="#AEACAC" display="flex">
                                Verification & Opt-in required
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}

                <Box mt="1.25rem">
                  <Typography variant="body1" className="taskTitle">Claim Preview</Typography>

                  {reward.drop_type_data.length > 0 && reward.drop_type === "allow_list" && (
                    <DefaultLayout mt="1.25rem" details={reward.drop_type_data[0].description}>
                      <AllowListPreview reward={reward} info={reward.drop_type_data[0]} setOpenCopiedBar={setOpenCopiedBar} />
                    </DefaultLayout>
                  )}
                  {reward.drop_type_data.length > 0 && reward.drop_type === "coupon_code" && (
                    <DefaultLayout details={reward.drop_type_data[0].details} mt="1.25rem">
                      <CouponCodeClaimed reward={reward} info={reward.drop_type_data[0]} setOpenCopiedBar={setOpenCopiedBar} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "gated_content" && (
                    <DefaultLayout mt="1.25rem">
                      <GatedContentPreview reward={reward} info={reward.drop_type_data} />
                    </DefaultLayout>
                  )}
                  {reward.drop_type_data.length > 0 && reward.drop_type === "event_pass" && (
                    <DefaultLayout mt="1.25rem">
                      <EventPassPreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "merchandise" && (
                    <DefaultLayout mt="1.25rem">
                      <MerchandisePreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "raffle" && (
                    <DefaultLayout mt="1.25rem">
                      <RafflePreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "poll" && (
                    <DefaultLayout mt="1.25rem">
                      <PollPreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "member_selection" && (
                    <DefaultLayout mt="1.25rem">
                      <MemberSelectionPreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}

                  {reward.drop_type_data.length > 0 && reward.drop_type === "custom" && (
                    <DefaultLayout mt="1.25rem">
                      <CustomRewardPreview reward={reward} info={reward.drop_type_data[0]} />
                    </DefaultLayout>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {deleteModal && <DeleteReward open={deleteModal} handleClose={() => setDeleteModal(false)} dropId={reward.id} dropsData={reward} />}
      {open && <EditReward editData={reward} open={open} handleClose={() => setOpen(false)} setUpdateSuccessAlert={setUpdateSuccessAlert} loadList={props.loadList} />}
      {endReward && <EndReward open={endReward} handleClose={() => setEndReward(false)} dropId={reward.id} dropsData={reward} />}
      {checkInEvent && <CheckinAttendees open={checkInEvent} handleClose={() => setCheckInEvent(false)} dropsData={reward} setOpenCopiedBar={setOpenCopiedBar} />}
      {drawRaffleWinner && <DrawWinners open={drawRaffleWinner} handleClose={() => setDrawRaffleWinner(false)} dropsData={reward} setNotifyWinner={setNotifyWinner} setNotifyRaffleObj={setNotifyRaffleObj} />}

      {pollResult && <PollResultModal open={pollResult} handleClose={() => setPollResult(false)} reward={reward} info={reward.drop_type_data[0]} />}
      {notifyWinner && <NotifyRaffleWinner open={notifyWinner} handleClose={() => setNotifyWinner(false)} notifyRaffleObj={notifyRaffleObj} dropsData={reward} setShowSentAlert={setShowSentAlert} />}

      {openCopiedBar && (
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={openCopiedBar} autoHideDuration={5000} key={vertical + horizontal}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Copied to clipboard!
          </Alert>
        </Snackbar>
      )}
    
    </Box>
  );
};

const mapStateToProps = (state) => ({
  page: state.page,
  rewardList: state.rewardList,
  rewardCRD: state.rewardCRD,
  user: state.user,
  showToast: state.showToast,
  raffleWinners: state.raffleWinners,
});

const mapDispatchToProps = {
  setToast,
  duplicateRewardDrop,
  getRaffleWinners,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDetail);
